<template>
  <div class="noData">
    <p class="noDataImg"></p>
    <p class="noDataText">{{noDataText}}</p>
  </div>
</template>

<script>
export default {
  props: {
    noDataText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss">
.noData {
  width: 100%;
  padding-top: 2.61rem;
  .noDataImg {
    margin: 0 auto;
    width: 5.39rem;
    height: 4.08rem;
    background: url("../../assets/images/noData.png") no-repeat;
    background-size: 100% 100%;
  }
  .noDataText {
    font-size: 0.35rem;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: #999999;
    margin-top: 0.24rem;
    text-align: center;
  }
}
</style>
