<template>
  <div
    class="knowledge-base-list"
    v-if="questionList"
  >
    <heads title="收藏夹"></heads>
    <van-tabs
      v-model="activeTag"
      @change="changeTag"
      class="fixed-top"
    >
      <van-tab
        v-for="item in tagsList"
        :key="item.id"
        :title="item.title"
        class="tabs-style"
      >
        <Tag
          v-for="(elem,index) in tags2List"
          :key="elem.label_id"
          :title="elem.content"
          :active2Tag="active2Tag==index"
          @changeActive="changeActive(index)"
        />
      </van-tab>
    </van-tabs>
    <div
      v-show="questionList"
      class="knowledge-content"
      :style="{marginTop: heightTags}"
    >
      <q-a-module
        v-for="item in questionList"
        :key="item.online_data_id"
        :obj="item"
        @toQADetail="toQADetail"
      ></q-a-module>
    </div>
    <noData
      v-show="questionList.length == 0"
      :noDataText="noDataText"
    />
    <img
      :src="require('@/assets/images/common_icon_add.png')"
      class="add-qa-detail"
      @click="addQA"
    >
  </div>
</template>

<script>
import Header from "@/components/Header";
import noData from "@/components/noData";
import Tag from "./Components/Tag.vue";
import QAModule from "./Components/QAModule.vue";
import { tagsList } from "@/utils/mock.js";
import { getLabelList, showKnowledgeBase } from "@/fetch/api/knowdgebase.js";
export default {
  components: {
    heads: Header,
    Tag,
    QAModule,
    noData,
  },
  data() {
    return {
      activeTag: 0, //选中的标签
      active2Tag: 0, //选中的二级标签
      tagsList: tagsList, //标签列表
      tags2List: null, //二级标签列表
      heightTags: null, //标签高度
      questionList: null,
      noDataText: "暂无数据",
      broker_id: localStorage.getItem("broker_id"), //用户ID
    };
  },
  computed: {},
  methods: {
    getTabLabel() {
      if (this.activeTag == 0) {
        this.tags2List = null;
        this.tags2List
          ? (this.heightTags = "2.8rem")
          : (this.heightTags = "1.7rem");
        return;
      }
      getLabelList({
        broker_id: this.broker_id,
        parent_id: this.activeTag,
      }).then((res) => {
        if (res.code == 200 && res.result.data) {
          res.result.data.unshift({
            content: "全部",
            label_id: 0,
            official: 0,
            parent_id: 0,
          });
          this.tags2List = res.result.data;
          this.tags2List
            ? (this.heightTags = "2.8rem")
            : (this.heightTags = "1.7rem");
        }
      });
    },
    getList() {
      let params = {
        broker_id: this.broker_id,
        parent_id: this.activeTag,
        label_id:
          this.tags2List && this.tags2List.length
            ? this.tags2List[this.active2Tag].label_id
            : 0,
        official:
          this.tags2List && this.tags2List.length
            ? this.tags2List[this.active2Tag].official
            : 0,
      };
      showKnowledgeBase(params).then((res) => {
        if (res.code == 200) {
          this.questionList = res.result.data;
        }
      });
    },
    changeTag() {
      this.active2Tag = 0;
      this.getTabLabel();
      this.getList();
    },
    changeActive(index) {
      this.active2Tag = index;
      this.getList();
    },
    toQADetail(obj) {
      this.$router.push(`/knowledge-base/detail/${obj.online_data_id}`);
    },
    addQA() {
      this.$router.push(`/knowledge-base/edit/0?type=add`);
    },
  },
  created() {
    // this.getTabLabel();
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.knowledge-base-list {
  background-color: #f9f9f9;
  .fixed-top {
    position: fixed;
    top: 1.17rem;
    left: 0;
    width: 100%;
  }
  .van-tabs {
    .tabs-style {
      width: 100%;
      height: 1.2rem;
      background: #f1f1f1;
      display: flex;
      align-items: center;
      overflow-x: auto;
      padding-right: 0.4rem;
    }
    .tabs-style::-webkit-scrollbar {
      display: none;
    }
    /deep/ .van-tabs__line {
      width: 0.75rem;
      height: 0.11rem;
      background: #346eea;
      border-radius: 0.11rem;
      bottom: 20px;
    }
    .van-tabs__nav {
      background: #f9f9f9;
    }
    .van-tab {
      color: #666;
    }
    .van-tab--active {
      font-size: 0.45rem;
      font-weight: 500;
      color: #222222;
      line-height: 0.64rem;
    }
  }
  .knowledge-content {
    width: 100%;
    margin-top: 1.7rem;
    .q-a-module .answer p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  .add-qa-detail {
    width: 1.33rem;
    height: 1.33rem;
    position: fixed;
    right: 4%;
    bottom: 8.62%;
  }
}
</style>
